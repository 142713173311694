<template>
  <table class="table table-striped small m-0">
    <thead class="bg-white">
      <tr>
        <th scope="col" class="border-bottom px-3">Date</th>
        <th scope="col" class="border-bottom px-3">Phase</th>
        <th scope="col" class="border-bottom px-3">Total calories</th>
        <th scope="col" class="border-bottom px-3">Carb (g)</th>
        <th scope="col" class="border-bottom px-3">Protein (g)</th>
        <th scope="col" class="border-bottom px-3">Fat (g)</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(record, recordIndex) in records" :key="`key-${recordIndex}`">
        <td class="px-3">
          <div>{{ record.dateJs.format('D MMM YYYY') }}</div>
          <div class="smaller text-muted">{{ record.day }}</div>
        </td>
        <td class="px-3">{{ getPlan(record) }}</td>
        <td class="px-3">{{ getCalories(record) }}</td>
        <td class="px-3" v-html="getCarb(record)"></td>
        <td class="px-3" v-html="getProtein(record)"></td>
        <td class="px-3" v-html="getFat(record)"></td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  name: 'NutritionMacrosTable',
  props: {
    userId: {
      type: String,
      required: true,
    },
    records: {
      type: Array,
      required: true,
    },
  },
  methods: {
    getCalories(record) {
      const { dayMacros } = record;
      if (!dayMacros) return '-';
      const { total: { value } } = dayMacros;
      return value;
    },
    getCarb(record) {
      const { dayMacros } = record;
      if (!dayMacros) return '-';
      const calories = dayMacros.total.value;
      const { c: { value } } = dayMacros;
      const perc = Math.round(((value * 4) * 100) / calories);
      return `
        <div>${value}</div>
        <div class="smaller text-muted">${Number.isNaN(perc) ? 0 : perc}%</div>
      `;
    },
    getProtein(record) {
      const { dayMacros } = record;
      if (!dayMacros) return '-';
      const calories = dayMacros.total.value;
      const { p: { value } } = dayMacros;
      const perc = Math.round(((value * 4) * 100) / calories);
      return `
        <div>${value}</div>
        <div class="smaller text-muted">${Number.isNaN(perc) ? 0 : perc}%</div>
      `;
    },
    getFat(record) {
      const { dayMacros } = record;
      if (!dayMacros) return '-';
      const calories = dayMacros.total.value;
      const { f: { value } } = dayMacros;
      const perc = Math.round(((value * 4) * 100) / calories);
      return `
        <div>${value}</div>
        <div class="smaller text-muted">${Number.isNaN(perc) ? 0 : perc}%</div>
      `;
    },
    getPlan(record) {
      const { dayPlan } = record;
      if (typeof dayPlan !== 'number') return '-';
      return this.$options.filters.dailyMealPlan(dayPlan);
    },
  },
  data() {
    return {
      categories: ['maintenance', 'surplus', 'deficit'],
    };
  },
  mounted() {
    this.records.reverse();
  },
};
</script>

<style lang="scss" scoped>
@import '@/scss/vars.scss';

thead {
  position: sticky;
  top: calc(7rem + 2px);
}

thead th {
  position: relative;

  &::after {
    content: "";
    pointer-events: none;
    position: absolute;
    left: 0;
    right: 0;
    bottom: -1px;
    height: 1px;
    background-color: $borderColor;
  }
}

</style>
